document.body.addEventListener('touchstart', function(){ });
function resizeHeader(){
  if($(window).width()>1024){
    $('.header_nav>li').hover(function(){
        $(this).children('div').show();
        $(this).addClass('active')
    },function(){
        $(this).children('div').hide();
        $(this).removeClass('active');

    });
    // 会员
    $('.header .login_btn .login_box').unbind('hover');
    $('.header .login_btn .login_box').hover(function(){
        $('.header .login_btn .login_box ul').show();
    },function(){
        $('.header .login_btn .login_box ul').hide();
    });
  }else{
    $('.header_nav>li').unbind();
    $('.header_nav>li').click(function(e){
      e.stopPropagation();
      $(this).toggleClass('active').children('div').toggleClass('active');
      $(this).siblings().removeClass('active').children().removeClass('active');
    });
    $('.header .menu dl').click(function(e){
      e.stopPropagation();
      $(this).children().toggleClass('active');
      $(this).siblings().removeClass('active').children().removeClass('active');
    });
    $('.header_nav>li div').click(function(e){
        e.stopPropagation();
    });
    $('.header .menu dl dd').click(function(e){
      e.stopPropagation();
    });
    // 会员
    $('.header .login_box').unbind('click');
    $('.header .login_box').click(function(){
        if($('.header_box').attr('class').indexOf('active')>-1){
            $('.header_box').removeClass('active');
            $('.header .toggle').removeClass('active');
        }
        if($('.header .login_box ul').css("display")=='none'){
            $('.header .login_box ul').show();
            $(".header_bg").addClass('active');
        }else{
            $('.header .login_box ul').hide();
            $(".header_bg").removeClass('active');
        }
        if($('.header_box .toggle').attr('class').indexOf('active')>-1){
          $('.header .header_nav').removeClass('active');
          $('.header_box .toggle').removeClass('active');
          $('.header').removeClass('active');
        }
    });
  }
};
resizeHeader();
$(window).resize(function(){
  resizeHeader();
});
$('.header_box .toggle').click(function(){
  $(this).toggleClass('active');
  $('.header_nav').toggleClass('active');
  $('.header_bg').toggleClass('active');
  $('.header').toggleClass('active');
  if($('.header .login_box ul').css("display")=='block'){
      $('.header .login_box ul').hide();
      $(".header_bg").addClass('active');
  }
});
$(".header_bg").click(function(){
  $(this).removeClass('active');
  $('.header .header_nav').removeClass('active');
  $('.header_box .toggle').removeClass('active');
  $('.header').removeClass('active');
  $('.header .login_box ul').hide();
});